.Index-root-26{
    padding-top: 0px !important;
    text-align:start !important;
}
.ReactTable .rt-thead.-filters input {
    color: black !important;
    }
.container-acceuil{
    padding-top: 5%;
    margin-left: 5%;
}
.Forum-acceuil-style{
font-size: 40px;
padding-left: 10px;
color: #F21616;
}
.Padres-acceuil-style{
font-size: 40px;

}
.dashboard-label-style{
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: grey;
}
.dadhboard-value-style{
    display: flex !important;
    align-items: center;
    justify-content: center;
    line-height: 26px;
    margin-top: 10px;
}
.dashboard-value-forum{
    color: black;
    font-size: 18px;
    font-weight: 700;
}
.dashboard-value-padres{
    color: black;
    font-size: 18px;
    font-weight: 700;
}
.dashboard-row-statistic{
    margin-top: 2%;
    justify-content: space-between;
}
.dashboard-refresh-button{
    font-size: 22px;
}
.dashboard-row-title{
    display: flex;
    align-items: center;
}
.ant-card-body{
    padding: 12px !important;
}
.dashboard-value-euribor{
    color: black;
    font-size: 18px;
    font-weight: 300;
}
.dashboard-value-styleCentred{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 26px;
    margin-top: 10px;
}
.dashboard-synthese-grid{
    font-size: 18px;
    line-height: 26px;
    color: rgba(0, 44, 63, 0.78);
    padding-left: 10px;
}
.card-dashboard-element-synthese{
    height: 70px !important;
    border-radius: 20px !important;
}
.dashboard-synthese-style-one{
    display: flex !important;
    align-items: center;
}
.dashboard-synthese-style-two{
    display: flex !important;
    padding-left: 10px;
}
/* [class*=sidebar-dark-] {
    background-color: #CD4542
    !important;
} */
.sideBar-modal-notification-btn-show{
    font-size: 16px;
    font-weight: 700;
    color: #F21616;
    cursor: pointer;
    padding: 0px 10px 0px 0px;
}
.sideBar-modal-notification-btn-delete{
    font-size: 16px;
    font-weight: 700;
    color: #F21616;
    cursor: pointer;
    padding: 0px 0px 0px 0px;
}
.ant-table-cell{
    font-weight: 600 !important;
}
.red-back{
    background-color: #a60000 !important;
}
.white-color{
    color: white !important;
}
.white{
    background-color: white;
}
.dashboard-mobile-title{
    color: #BC0703;
    font-size: 25px;
    padding-bottom: 30px;
    font-weight: 600;
}
.card-dashboard{
    margin: 20px !important;
    border-radius: 10px !important;
}
.dashboard-mobile-btn-refresh{
    display: flex !important;
    align-items: baseline;
    justify-content: end;
}
.dashboard-mobile-eurobird-card-text-style{
    font-weight: bold;
    color: #333 !important;
}
.layout-fixed .main-sidebar{
    text-align: initial !important;
}
.jss26{
    padding-top: 0px !important;
}
.MuiTypography-body1-168{
    text-align: center !important;
}
.nav-pills .nav-link:not(.active):hover{
    background-color: #BC0703 !important ;
    color: white !important;
}
.navbar-white{
    background-color: #BC0703 !important;
}
.navbar-light .navbar-nav .nav-link{
    color: white !important;
}
.fontawsome-style{
    margin-left: 7px;
    margin-right: 10px;
}
.sideBar-menu-libelle{
    padding-left: 33px;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
    display: flex !important;
    align-items:center !important;
    justify-content: space-around !important;
    font-weight: 700 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#BC0703 !important ;
}
.ant-tabs-ink-bar{
    background:#BC0703 !important;
}
.ant-tabs-tab .ant-tabs-tab-btn:hover{
    color:#BC0703 !important ;
}
.card-element-dashboard{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-bottom: 10px;
}
.Pardes-title-dashboard{
    color: #800000;
    font-size: 30px;
    font-weight: 600;
    border-bottom:3px solid #800000;
    height: 35px;
}
.card-data-pardes-dashboard{
    width: 33%;
    border: 2px solid #800000;
    margin-right: 10px;
    border-radius: 22px;
}
.card-title-style-dashboard{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.dashboard-refresh-button{
    position: absolute !important;
    top: 5px;
    right: 0;
}
.Forum-title-dashboard{
    color: #345DA6;
    font-size: 30px;
    font-weight: 600;
    border-bottom:3px solid #345DA6;
    height: 35px;
}
.card-data-forum-dashboard{
    width: 33%;
    border: 2px solid #345DA6;
    margin-right: 10px;
    border-radius: 22px;
}
.dashboard-synthese-grid-euribor{
    font-size: 18px;
    line-height: 26px;
    color: black;
    
}
.dashboard-synthese-style-euribod-one{
    display: flex !important;
    align-items: center;
    padding-top: 20px !important;
    text-align: start !important;
}
