.ReactTable.-striped .rt-tr.-odd {
  background: rgba(249, 194, 190, 0.1);
  text-align: right;
}

.ReactTable.-striped .rt-tr.-even {
  background: #e7e0e0;
  text-align: right;
  border-top: solid 1px #a60000;
  border-bottom: solid 1px #a60000;
}

.ReactTable.withMaxHeight .rt-tbody .rt-tr-group {
  max-height: 35px;
}

.ReactTable.noScroll .rt-tbody {
  overflow-y: hidden;
}

div.rt-th:nth-child(1) {
  background-color: #a60000;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: silver;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 5px 0 0 rgba(255, 255, 255, 1);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -5px 0 0 rgba(255, 255, 255, 1);
}

.ReactTable {
  fontfamily: '"Roboto", "Helvetica", "Arial", sans-serif';
}
/*.ReactTable .rt-tbody .rt-tr-group {
  height: 35px;
}*/
